"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCartQuery = exports.getProductQuery = exports.removeLineItemsMutation = exports.addLineItemsMutation = exports.createCartMutation = void 0;
// Mutations
exports.createCartMutation = "mutation createCart($cartInput: CartInput) {\n  cartCreate(input: $cartInput) {\n    cart {\n      id\n      checkoutUrl\n      lines(first: 100) {\n        edges {\n          node {\n            id\n            merchandise {\n              ... on ProductVariant {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n      cost {\n        totalAmount {\n          amount\n          currencyCode\n        }\n        subtotalAmount {\n          amount\n          currencyCode\n        }\n      }\n    }\n  }\n}";
var addLineItemsMutation = function (_a) {
    var cartId = _a.cartId, selectedVariantId = _a.selectedVariantId, quantity = _a.quantity;
    return "mutation addToCart {\n    cartLinesAdd(cartId: \"".concat(cartId, "\", \n    lines: {\n      merchandiseId: \"").concat(selectedVariantId, "\", \n      quantity: ").concat(quantity, "\n    }) {\n      userErrors {\n        field\n        message\n      }\n      cart {\n        checkoutUrl\n        lines(first: 10) {\n          edges {\n            node {\n              id\n              quantity\n            }\n          }\n        }\n        cost {\n          totalAmount {\n            amount\n            currencyCode\n          }\n          subtotalAmount {\n            amount\n            currencyCode\n          }\n        }\n      }\n    }\n  }");
};
exports.addLineItemsMutation = addLineItemsMutation;
var removeLineItemsMutation = function (_a) {
    var cartId = _a.cartId, lineId = _a.lineId;
    return "\n  mutation cartLinesRemove {\n    cartLinesRemove(\n      cartId: \"".concat(cartId, "\"\n      lineIds: \"").concat(lineId, "\"\n    ) {\n      cart {\n        id\n        checkoutUrl\n        lines(first: 10) {\n          edges {\n            node {\n              id\n              merchandise {\n                ... on ProductVariant {\n                  id\n                  title\n                  image {\n                    url\n                  }\n                  price {\n                    amount\n                    currencyCode\n                  }\n                }\n              }\n              quantity\n            }\n          }\n        }\n        cost {\n          totalAmount {\n            amount\n            currencyCode\n          }\n          subtotalAmount {\n            amount\n            currencyCode\n          }\n        }\n      }\n      userErrors {\n        field\n        message\n      }\n    }\n  }\n");
};
exports.removeLineItemsMutation = removeLineItemsMutation;
// Queries
var getProductQuery = function (_a) {
    var id = _a.id;
    return "query ProductQuery {\n  product(id: \"gid://shopify/Product/".concat(id, "\") {\n    id\n    availableForSale\n    description\n    handle\n    title\n    options {\n      name\n      values\n    }\n    variants(first: 100) {\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      nodes {\n        id\n        title\n        price {\n          amount\n          currencyCode\n        }\n        availableForSale\n        sku\n        image {\n          id\n          url\n          altText\n        }\n        selectedOptions {\n          name\n          value\n        }\n      }\n    }\n  }\n}\n");
};
exports.getProductQuery = getProductQuery;
var getCartQuery = function (_a) {
    var cartId = _a.cartId;
    return "query {\n  cart(\n    id: \"".concat(cartId, "\"\n  ) {\n    id\n    createdAt\n    updatedAt\n    checkoutUrl\n    lines(first: 10) {\n      edges {\n        node {\n          id\n          quantity\n          merchandise {\n            ... on ProductVariant {\n              id\n              title\n              image {\n                url\n              }\n              price {\n                amount\n                currencyCode\n              }\n            }\n          }\n        }\n      }\n    }\n    cost {\n      totalAmount {\n        amount\n        currencyCode\n      }\n      subtotalAmount {\n        amount\n        currencyCode\n      }\n    }\n  }\n}");
};
exports.getCartQuery = getCartQuery;
