"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("../../constants");
/**
 * Mounts Product Variant Radio selector template
 * @param name name of the variant option
 * @param values values of the variant option
 * @param index index of the variant option
 * @param getDisabledStatus function to get the disabled status of the variant option
 * @param selectedVariantOptions object with the selected variant options
 * @param firstAvailableOptions object to set the first available variant options
 */
function VariantRadio(_a) {
    var name = _a.name, values = _a.values, optionIndex = _a.index, getDisabledStatus = _a.getDisabledStatus, selectedVariantOptions = _a.selectedVariantOptions, firstAvailableOptions = _a.firstAvailableOptions;
    var options = (0, lodash_1.map)(values, function (value) { return renderOptions(value); });
    // Sets the first available option as selected and updates the first available option object with the selected value
    function checkSelectedOption(value) {
        if (selectedVariantOptions[name] === value &&
            firstAvailableOptions[name] !== value) {
            firstAvailableOptions[name] = value;
            return true;
        }
        else {
            return;
        }
    }
    function renderOptions(value) {
        var isDisabled = getDisabledStatus({
            index: optionIndex,
            value: value,
            name: name,
        });
        var isSelected = checkSelectedOption(value);
        return "<span>\n      <input  \n        id=\"".concat(name).concat(constants_1.VARIANT_SELECTOR_NAME_SUFFIX).concat(value, "\" \n        class=\"variant-select\"\n        data-type=\"").concat(name, "\"\n        data-index=\"").concat(optionIndex, "\"\n        ").concat(isSelected ? 'checked' : '', "\n        type=\"radio\" \n        name=\"").concat(name).concat(constants_1.VARIANT_SELECTOR_NAME_SUFFIX, "\" \n        value=\"").concat(value, "\" \n        ").concat(isDisabled ? 'disabled' : '', "\n      />\n      <label for=\"").concat(name).concat(constants_1.VARIANT_SELECTOR_NAME_SUFFIX).concat(value, "\">\n        ").concat(value, "\n      </label>\n    </span>");
    }
    return "<div class=\"product-form-input\">\n    <label class=\"variant-label\">".concat(name, "</label>\n    <div class=\"radio-options-container\">\n      ").concat(options.join(''), "\n    <div>\n  </div>\n  ");
}
exports.default = VariantRadio;
