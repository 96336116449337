"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("./constants");
/**
 * Mounts Product Wrapper element template
 * @param {Object} product data.
 */
function ProductElement(_a) {
    var product = _a.product, useUrl = _a.useUrl, isOutOfStock = _a.isOutOfStock;
    if ((0, lodash_1.isEmpty)(product)) {
        return null;
    }
    var id = product.id, url = product.url, title = product.title, price = product.price, description = product.description, src = product.preview_image.image.medium;
    var children = "<div class=\"product-image-container\">\n    <img src=\"".concat(src, "\" class=\"product-image\" alt=\"").concat(title, "\" />\n    ").concat(isOutOfStock ? "<span class=\"out-of-stock-text\">Out of Stock </span>" : '', "\n  </div>\n  <div class=\"product-details\">\n    <div class=\"product-details-header\">\n      <h4 class=\"product-title\">\n        <span style=\"overflow: hidden\">").concat(title, "</span>\n      </h4>\n      <h4 class=\"product-price\">$").concat(price, "</h4>\n    </div>\n    <div class=\"product-description\">\n        <span>").concat(description.length > constants_1.MAX_PRODUCT_DESCRIPTION_LENGTH
        ? "".concat(description.substring(0, constants_1.MAX_PRODUCT_DESCRIPTION_LENGTH), "...")
        : description, "\n        </span>\n    </div>\n  </div>");
    if (useUrl) {
        return "<a target=\"_blank\" href=\"".concat(url, "\" id=\"product-").concat(id, "\" href=\"").concat(url, "\" class=\"product-wrapper\">\n      ").concat(children, "\n    </a>");
    }
    return "<div id=\"product-".concat(id, "\" class=\"product-wrapper\">\n    ").concat(children, "\n  </div>");
}
exports.default = ProductElement;
