"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItemCount = void 0;
var getItemCount = function (items) {
    // go through items and get the total quantity
    return items.reduce(function (acc, _a) {
        var node = _a.node;
        var quantity = node.quantity;
        return acc + quantity;
    }, 0);
};
exports.getItemCount = getItemCount;
