"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderButtonText = void 0;
var lodash_1 = require("lodash");
var constants_1 = require("./constants");
/**
 * Mounts button text element template
 * @param {string} text string to replace the default copy.
 */
function renderButtonText(text) {
    if (!(0, lodash_1.isEmpty)(text)) {
        return "<span class=\"featured-prodcuts-btn-text\">&nbsp;".concat(text, "</span>\n          <span class=\"featured-prodcuts-btn-text-small\" hidden>&nbsp;").concat(text, "</span>");
    }
    return "<span class=\"featured-prodcuts-btn-text\">&nbsp;Featured Products</span>\n        <span class=\"featured-prodcuts-btn-text-small\" hidden>&nbsp;Products</span>";
}
exports.renderButtonText = renderButtonText;
/**
 * Mounts Feature Products container element template
 * @param {Object} featuredProducts data for current episode.
 */
function default_1() {
    return "<input type=\"checkbox\" id=\"minimize-products-checkbox\" />\n    <div\n      class=\"".concat(constants_1.FEATURED_PRODUCTS_CONTAINER_ELEMENT_ID, "\"\n      id=\"").concat(constants_1.FEATURED_PRODUCTS_CONTAINER_ELEMENT_ID, "\" hidden>\n      <label class=\"featured-products-btn\" for=\"minimize-products-checkbox\">\n        <span role=\"img\" class=\"icon\">\n          <svg\n            viewBox=\"64 64 896 896\"\n            focusable=\"false\"\n            data-icon=\"left\"\n            width=\"1em\"\n            height=\"1em\"\n            fill=\"currentColor\"\n            aria-hidden=\"true\"\n          >\n            <path\n              d=\"M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z\"\n            />\n          </svg>\n        </span>\n        <div class=\"button-text-container\">\n          ").concat(renderButtonText(), "\n        </div>\n      </label>\n      <div id=\"").concat(constants_1.PRODUCT_LIST_ELEMENT_ID, "\">\n      </div>\n    </div>");
}
exports.default = default_1;
