"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("../../constants");
/**
 * Mounts Product Variant selector template
 * @param name name of the variant option
 * @param values values of the variant option
 * @param index index of the variant option
 * @param getDisabledStatus function to get the disabled status of the variant option
 * @param selectedVariantOptions object with the selected variant options
 * @param firstAvailableOptions object to set the first available variant options
 */
function VariantSelect(_a) {
    var name = _a.name, values = _a.values, optionIndex = _a.index, getDisabledStatus = _a.getDisabledStatus, selectedVariantOptions = _a.selectedVariantOptions, firstAvailableOptions = _a.firstAvailableOptions;
    var options = (0, lodash_1.map)(values, function (value) { return renderOptions(value); });
    // Sets the first available option as selected and updates the first available option object with the selected value
    function checkSelectedOption(value) {
        if (selectedVariantOptions[name] === value &&
            firstAvailableOptions[name] !== value) {
            firstAvailableOptions[name] = value;
            return true;
        }
        else {
            return;
        }
    }
    function renderOptions(value) {
        var isDisabled = getDisabledStatus({
            index: optionIndex,
            value: value,
            name: name,
        });
        var isSelected = checkSelectedOption(value);
        return "<option value=\"".concat(value, "\" \n    ").concat(isSelected ? 'selected' : '', "\n    ").concat(isDisabled ? 'disabled' : '', ">").concat(value, "</option>");
    }
    return "<div class=\"product-form-input\">\n    <label class=\"variant-label\" for=\"".concat(name).concat(constants_1.VARIANT_SELECTOR_NAME_SUFFIX, "\">").concat(name, "</label>\n    <div class=\"select-wrapper\">\n      <select class=\"variant-select\" data-selector-type=\"select\" data-type=\"").concat(name, "\" data-index=\"").concat(optionIndex, "\" id=\"").concat(name).concat(constants_1.VARIANT_SELECTOR_NAME_SUFFIX, "\" name=\"").concat(name).concat(constants_1.VARIANT_SELECTOR_NAME_SUFFIX, "\">\n        ").concat(options.join(''), "\n      </select>\n    </div>\n  </div>");
}
exports.default = VariantSelect;
