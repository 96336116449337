"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderItemsCount = void 0;
var constants_1 = require("../../constants");
/**
 * Mounts Items Count template
 * @param itemsInBag number of items in the bag
 */
function renderItemsCount(itemsInBag) {
    if (itemsInBag && itemsInBag > 0) {
        return "<span class=\"items-count\">".concat(itemsInBag, "</span>");
    }
    return '';
}
exports.renderItemsCount = renderItemsCount;
/**
 * Mounts Shopify Bag Button template
 * @param itemsInBag number of items in the bag
 */
function default_1(itemsInBag) {
    return "\n  <button class=\"".concat(constants_1.SHOPIFY_BAG_BUTTON_CLASS, "\">\n    ").concat(renderItemsCount(itemsInBag), "\n    <svg width=\"32\" height=\"32\" viewBox=\"0 0 32 32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n      <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" \n          d=\"M8.35102 12.7725C8.43547 12.1814 8.94177 11.7422 \n              9.53896 11.7422H22.9419C23.5391 11.7422 24.0454 12.1814 \n              24.1299 12.7725L25.8034 24.4873C26.0099 25.9332 24.888 \n              27.2267 23.4275 27.2267H9.05335C7.59284 27.2267 6.47093 \n              25.9332 6.67748 24.4873L8.35102 12.7725Z\" \n          stroke=\"white\" stroke-width=\"2\"\n      />\n      <path d=\"M20.6652 15.0602V8.02184C20.6652 5.80059 19.0806 3.9999 17.1259 \n              3.9999H15.3562C13.4015 3.9999 11.8169 5.80059 11.8169 8.02184V15.0602V15.0602\" \n          stroke=\"white\" stroke-width=\"2\"\n      />\n      <path d=\"M7.94511 22.2498H24.5356\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"square\"/>\n    </svg>\n  </button>\n  ");
}
exports.default = default_1;
