"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStringToSeconds = void 0;
var lodash_1 = require("lodash");
var convertStringToSeconds = function (durationString) {
    if ((0, lodash_1.isEmpty)(durationString)) {
        return 0;
    }
    var hmsValues = (0, lodash_1.split)(durationString, ':');
    if (hmsValues.length < 3) {
        return (0, lodash_1.toNumber)(hmsValues[0]) * 60 + (0, lodash_1.toNumber)((0, lodash_1.split)(hmsValues[1], '.')[0]);
    }
    return ((0, lodash_1.toNumber)(hmsValues[0]) * 3600 +
        (0, lodash_1.toNumber)(hmsValues[1]) * 60 +
        (0, lodash_1.toNumber)((0, lodash_1.split)(hmsValues[2], '.')[0]));
};
exports.convertStringToSeconds = convertStringToSeconds;
