"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var storefront_api_client_1 = require("@shopify/storefront-api-client");
var lodash_1 = require("lodash");
var constants_1 = require("./constants");
var operations_1 = require("./operations");
var Shopify = /** @class */ (function () {
    function Shopify(config) {
        this.client = null;
        this.buildClient(config);
    }
    Shopify.prototype.buildClient = function (_a) {
        var domain = _a.domain, storefrontAccessToken = _a.storefrontAccessToken;
        if (!(0, lodash_1.isEmpty)(domain) && !(0, lodash_1.isEmpty)(storefrontAccessToken)) {
            this.client = (0, storefront_api_client_1.createStorefrontApiClient)({
                storeDomain: domain,
                apiVersion: '2024-04',
                publicAccessToken: storefrontAccessToken,
            });
            return this.client;
        }
        throw new Error('Invalid Shopify Config.');
    };
    Shopify.prototype.checkClient = function () {
        if ((0, lodash_1.isEmpty)(this.client)) {
            throw new Error('Shopify client not built.');
        }
    };
    Shopify.prototype.getCartId = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cartId, _a, data, errors, cartCreate;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        cartId = sessionStorage.getItem(constants_1.CART_ID_KEY);
                        if (cartId) {
                            return [2 /*return*/, cartId];
                        }
                        return [4 /*yield*/, this.client.request(operations_1.createCartMutation)];
                    case 1:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        if (errors) {
                            console.error(errors.message);
                            return [2 /*return*/];
                        }
                        else {
                            cartCreate = data.cartCreate;
                            sessionStorage.setItem(constants_1.CART_ID_KEY, cartCreate.cart.id);
                            return [2 /*return*/, cartCreate.cart.id];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Shopify.prototype.getProduct = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, data, errors;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.checkClient();
                        return [4 /*yield*/, this.client.request((0, operations_1.getProductQuery)({ id: id }))];
                    case 1:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        if (errors) {
                            console.error(errors.message);
                            return [2 /*return*/];
                        }
                        if (data) {
                            return [2 /*return*/, data.product];
                        }
                        if (!data && !errors) {
                            throw new Error('Shopify product request failed.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Shopify.prototype.getSelectedVariant = function (_a) {
        var product = _a.product, variantOptions = _a.variantOptions;
        var productVariants = product.variants;
        var selectedVariant = productVariants.length === 1
            ? productVariants.nodes[0]
            : productVariants.nodes.find(function (variant) {
                return variant.selectedOptions.every(function (option) {
                    return variantOptions[option.name] === option.value;
                });
            });
        if (!selectedVariant) {
            throw new Error('Variant not found.');
        }
        return selectedVariant;
    };
    Shopify.prototype.addProductToBag = function (_a) {
        var product = _a.product, variantOptions = _a.variantOptions;
        return __awaiter(this, void 0, void 0, function () {
            var cartId, selectedVariant, _b, data, errors;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.checkClient();
                        return [4 /*yield*/, this.getCartId()];
                    case 1:
                        cartId = _c.sent();
                        selectedVariant = this.getSelectedVariant({
                            product: product,
                            variantOptions: variantOptions,
                        });
                        return [4 /*yield*/, this.client.request((0, operations_1.addLineItemsMutation)({
                                cartId: cartId,
                                selectedVariantId: selectedVariant.id,
                                quantity: variantOptions.quantity,
                            }))];
                    case 2:
                        _b = _c.sent(), data = _b.data, errors = _b.errors;
                        if (errors) {
                            console.error(errors.message);
                            return [2 /*return*/];
                        }
                        else {
                            return [2 /*return*/, data.cartLinesAdd.cart];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Shopify.prototype.getCart = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cartId, _a, data, errors;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.checkClient();
                        return [4 /*yield*/, this.getCartId()];
                    case 1:
                        cartId = _b.sent();
                        return [4 /*yield*/, this.client.request((0, operations_1.getCartQuery)({ cartId: cartId }))];
                    case 2:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        if (errors) {
                            console.error(errors.message);
                            return [2 /*return*/];
                        }
                        else {
                            return [2 /*return*/, data.cart];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Shopify.prototype.deleteCheckoutLineItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var cartId, _a, data, errors;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.checkClient();
                        return [4 /*yield*/, this.getCartId()];
                    case 1:
                        cartId = _b.sent();
                        return [4 /*yield*/, this.client.request((0, operations_1.removeLineItemsMutation)({ cartId: cartId, lineId: id }))];
                    case 2:
                        _a = _b.sent(), data = _a.data, errors = _a.errors;
                        if (errors) {
                            console.error(errors.message);
                            return [2 /*return*/];
                        }
                        else {
                            return [2 /*return*/, data.cartLinesRemove.cart];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return Shopify;
}());
exports.default = Shopify;
